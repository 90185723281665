import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Form from '../components/Form'

//Components
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'

export default class ContactPage extends React.Component {
  render() {
    //Get Page Data
    const {data} = this.props

    // Parse Content for System Section
    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.pagecontent.frontmatter.image.childImageSharp.fluid}/>
        <div className="secondary-contain">
          <section className="columns">
            <div className="column is-8 is-offset-2">
              <h1 className="is-size-2">{data.pagecontent.frontmatter.title}</h1>
              <div className="contact-box">
                <h3>Joint Force Leadership</h3>
                <p><b>Email: </b>info@jfleaders.com<br/><b>Phone: </b>(305) 692-0392<br/></p>
              </div>
              <div className="contact-content" dangerouslySetInnerHTML={{
                  __html: data.pagecontent.html
                }}/>
            <div className="contact-contain">

            <Form/>
            </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>)
  }
}
export const pageQuery = graphql ` query ContactQuery {

        pagecontent: markdownRemark(frontmatter : {
          path: {
            eq: "/contact"
          }
        }) {
          id
          frontmatter {
            path
            title
            cta
            image {
              childImageSharp {
                fluid(maxWidth : 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
      `
